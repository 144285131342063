import { INaeProperty } from "@newageerp/v3.app.main-bundle";
export const NaeSProperties: INaeProperty[] = [
    {
        "schema": "key-value-data",
        "key": "analyticsId",
        "type": "string",
        "format": "",
        "title": "analyticsId",
        "additionalProperties": [],
        "description": "",
        "className": "KeyValueData",
        "isDb": true,
        "dbType": "varchar",
        "naeType": "string"
    },
    {
        "schema": "key-value-data",
        "key": "createdAt",
        "type": "string",
        "format": "date-time",
        "title": "createdAt",
        "additionalProperties": [],
        "description": "",
        "className": "KeyValueData",
        "isDb": true,
        "dbType": "datetime",
        "naeType": "string"
    },
    {
        "schema": "key-value-data",
        "key": "dataKey",
        "type": "string",
        "format": "",
        "title": "dataKey",
        "additionalProperties": [],
        "description": "",
        "className": "KeyValueData",
        "isDb": true,
        "dbType": "varchar",
        "naeType": "string"
    },
    {
        "schema": "key-value-data",
        "key": "dataValue",
        "type": "array",
        "format": "string",
        "title": "dataValue",
        "additionalProperties": [],
        "description": "",
        "className": "KeyValueData",
        "isDb": true,
        "dbType": "json",
        "naeType": "string_array"
    },
    {
        "schema": "key-value-data",
        "key": "id",
        "type": "integer",
        "format": "",
        "title": "",
        "additionalProperties": [],
        "description": "",
        "className": "KeyValueData",
        "isDb": true,
        "dbType": "int",
        "naeType": "number"
    },
    {
        "schema": "key-value-data",
        "key": "project",
        "type": "string",
        "format": "",
        "title": "project",
        "additionalProperties": [],
        "description": "",
        "className": "KeyValueData",
        "isDb": true,
        "dbType": "varchar",
        "naeType": "string"
    },
    {
        "schema": "key-value-data",
        "key": "updatedAt",
        "type": "string",
        "format": "date-time",
        "title": "updatedAt",
        "additionalProperties": [],
        "description": "",
        "className": "KeyValueData",
        "isDb": true,
        "dbType": "datetime",
        "naeType": "string"
    },
    {
        "schema": "key-value-data",
        "key": "uuid",
        "type": "string",
        "format": "",
        "title": "uuid",
        "additionalProperties": [],
        "description": "",
        "className": "KeyValueData",
        "isDb": true,
        "dbType": "varchar",
        "naeType": "string"
    },
    {
        "schema": "klaviyo-event",
        "key": "createdAt",
        "type": "string",
        "format": "date-time",
        "title": "createdAt",
        "additionalProperties": [],
        "description": "",
        "className": "KlaviyoEvent",
        "isDb": true,
        "dbType": "datetime",
        "naeType": "string"
    },
    {
        "schema": "klaviyo-event",
        "key": "id",
        "type": "integer",
        "format": "",
        "title": "",
        "additionalProperties": [],
        "description": "",
        "className": "KlaviyoEvent",
        "isDb": true,
        "dbType": "int",
        "naeType": "number"
    },
    {
        "schema": "klaviyo-event",
        "key": "metricId",
        "type": "string",
        "format": "",
        "title": "metricId",
        "additionalProperties": [],
        "description": "",
        "className": "KlaviyoEvent",
        "isDb": true,
        "dbType": "varchar",
        "naeType": "string"
    },
    {
        "schema": "klaviyo-event",
        "key": "metricName",
        "type": "string",
        "format": "",
        "title": "metricName",
        "additionalProperties": [],
        "description": "",
        "className": "KlaviyoEvent",
        "isDb": true,
        "dbType": "varchar",
        "naeType": "string"
    },
    {
        "schema": "klaviyo-event",
        "key": "profileEmail",
        "type": "string",
        "format": "",
        "title": "profileEmail",
        "additionalProperties": [],
        "description": "",
        "className": "KlaviyoEvent",
        "isDb": true,
        "dbType": "varchar",
        "naeType": "string"
    },
    {
        "schema": "klaviyo-event",
        "key": "profileId",
        "type": "string",
        "format": "",
        "title": "profileId",
        "additionalProperties": [],
        "description": "",
        "className": "KlaviyoEvent",
        "isDb": true,
        "dbType": "varchar",
        "naeType": "string"
    },
    {
        "schema": "klaviyo-event",
        "key": "sourceId",
        "type": "string",
        "format": "",
        "title": "sourceId",
        "additionalProperties": [],
        "description": "",
        "className": "KlaviyoEvent",
        "isDb": true,
        "dbType": "varchar",
        "naeType": "string"
    },
    {
        "schema": "session-data",
        "key": "_viewTitle",
        "type": "string",
        "format": "",
        "title": "",
        "additionalProperties": [],
        "description": "",
        "className": "SessionData",
        "isDb": false,
        "dbType": "",
        "naeType": "string"
    },
    {
        "schema": "session-data",
        "key": "analyticsId",
        "type": "string",
        "format": "",
        "title": "analyticsId",
        "additionalProperties": [],
        "description": "",
        "className": "SessionData",
        "isDb": true,
        "dbType": "varchar",
        "naeType": "string"
    },
    {
        "schema": "session-data",
        "key": "cookies",
        "type": "array",
        "format": "string",
        "title": "cookies",
        "additionalProperties": [],
        "description": "",
        "className": "SessionData",
        "isDb": true,
        "dbType": "json",
        "naeType": "string_array"
    },
    {
        "schema": "session-data",
        "key": "country",
        "type": "string",
        "format": "",
        "title": "country",
        "additionalProperties": [],
        "description": "",
        "className": "SessionData",
        "isDb": true,
        "dbType": "varchar",
        "naeType": "string"
    },
    {
        "schema": "session-data",
        "key": "createdAt",
        "type": "string",
        "format": "date-time",
        "title": "createdAt",
        "additionalProperties": [],
        "description": "",
        "className": "SessionData",
        "isDb": true,
        "dbType": "datetime",
        "naeType": "string"
    },
    {
        "schema": "session-data",
        "key": "email",
        "type": "string",
        "format": "",
        "title": "email",
        "additionalProperties": [],
        "description": "",
        "className": "SessionData",
        "isDb": true,
        "dbType": "varchar",
        "naeType": "string"
    },
    {
        "schema": "session-data",
        "key": "extraData",
        "type": "array",
        "format": "string",
        "title": "extraData",
        "additionalProperties": [],
        "description": "",
        "className": "SessionData",
        "isDb": true,
        "dbType": "json",
        "naeType": "string_array"
    },
    {
        "schema": "session-data",
        "key": "id",
        "type": "integer",
        "format": "",
        "title": "",
        "additionalProperties": [],
        "description": "",
        "className": "SessionData",
        "isDb": true,
        "dbType": "int",
        "naeType": "number"
    },
    {
        "schema": "session-data",
        "key": "ip",
        "type": "string",
        "format": "",
        "title": "ip",
        "additionalProperties": [],
        "description": "",
        "className": "SessionData",
        "isDb": true,
        "dbType": "varchar",
        "naeType": "string"
    },
    {
        "schema": "session-data",
        "key": "isEu",
        "type": "boolean",
        "format": "",
        "title": "isEu",
        "additionalProperties": [],
        "description": "",
        "className": "SessionData",
        "isDb": true,
        "dbType": "tinyint",
        "naeType": "bool"
    },
    {
        "schema": "session-data",
        "key": "isPurchased",
        "type": "boolean",
        "format": "",
        "title": "isPurchased",
        "additionalProperties": [],
        "description": "",
        "className": "SessionData",
        "isDb": true,
        "dbType": "tinyint",
        "naeType": "bool"
    },
    {
        "schema": "session-data",
        "key": "origin",
        "type": "string",
        "format": "text",
        "title": "origin",
        "additionalProperties": [],
        "description": "",
        "className": "SessionData",
        "isDb": true,
        "dbType": "longtext",
        "naeType": "text"
    },
    {
        "schema": "session-data",
        "key": "project",
        "type": "string",
        "format": "",
        "title": "project",
        "additionalProperties": [],
        "description": "",
        "className": "SessionData",
        "isDb": true,
        "dbType": "varchar",
        "naeType": "string"
    },
    {
        "schema": "session-data",
        "key": "query",
        "type": "array",
        "format": "string",
        "title": "query",
        "additionalProperties": [],
        "description": "",
        "className": "SessionData",
        "isDb": true,
        "dbType": "json",
        "naeType": "string_array"
    },
    {
        "schema": "session-data",
        "key": "referer",
        "type": "string",
        "format": "text",
        "title": "referer",
        "additionalProperties": [],
        "description": "",
        "className": "SessionData",
        "isDb": true,
        "dbType": "longtext",
        "naeType": "text"
    },
    {
        "schema": "session-data",
        "key": "slug",
        "type": "string",
        "format": "",
        "title": "slug",
        "additionalProperties": [],
        "description": "",
        "className": "SessionData",
        "isDb": true,
        "dbType": "varchar",
        "naeType": "string"
    },
    {
        "schema": "session-data",
        "key": "state",
        "type": "string",
        "format": "",
        "title": "state",
        "additionalProperties": [],
        "description": "",
        "className": "SessionData",
        "isDb": true,
        "dbType": "varchar",
        "naeType": "string"
    },
    {
        "schema": "session-data",
        "key": "updatedAt",
        "type": "string",
        "format": "date-time",
        "title": "updatedAt",
        "additionalProperties": [],
        "description": "",
        "className": "SessionData",
        "isDb": true,
        "dbType": "datetime",
        "naeType": "string"
    },
    {
        "schema": "session-data",
        "key": "useragent",
        "type": "string",
        "format": "text",
        "title": "useragent",
        "additionalProperties": [],
        "description": "",
        "className": "SessionData",
        "isDb": true,
        "dbType": "longtext",
        "naeType": "text"
    },
    {
        "schema": "session-data",
        "key": "uuid",
        "type": "string",
        "format": "",
        "title": "uuid",
        "additionalProperties": [],
        "description": "",
        "className": "SessionData",
        "isDb": true,
        "dbType": "varchar",
        "naeType": "string"
    },
    {
        "schema": "session-data-archive",
        "key": "_viewTitle",
        "type": "string",
        "format": "",
        "title": "",
        "additionalProperties": [],
        "description": "",
        "className": "SessionDataArchive",
        "isDb": false,
        "dbType": "",
        "naeType": "string"
    },
    {
        "schema": "session-data-archive",
        "key": "analyticsId",
        "type": "string",
        "format": "",
        "title": "analyticsId",
        "additionalProperties": [],
        "description": "",
        "className": "SessionDataArchive",
        "isDb": true,
        "dbType": "varchar",
        "naeType": "string"
    },
    {
        "schema": "session-data-archive",
        "key": "cookies",
        "type": "array",
        "format": "string",
        "title": "cookies",
        "additionalProperties": [],
        "description": "",
        "className": "SessionDataArchive",
        "isDb": true,
        "dbType": "json",
        "naeType": "string_array"
    },
    {
        "schema": "session-data-archive",
        "key": "country",
        "type": "string",
        "format": "",
        "title": "country",
        "additionalProperties": [],
        "description": "",
        "className": "SessionDataArchive",
        "isDb": true,
        "dbType": "varchar",
        "naeType": "string"
    },
    {
        "schema": "session-data-archive",
        "key": "createdAt",
        "type": "string",
        "format": "date-time",
        "title": "createdAt",
        "additionalProperties": [],
        "description": "",
        "className": "SessionDataArchive",
        "isDb": true,
        "dbType": "datetime",
        "naeType": "string"
    },
    {
        "schema": "session-data-archive",
        "key": "email",
        "type": "string",
        "format": "",
        "title": "email",
        "additionalProperties": [],
        "description": "",
        "className": "SessionDataArchive",
        "isDb": true,
        "dbType": "varchar",
        "naeType": "string"
    },
    {
        "schema": "session-data-archive",
        "key": "extraData",
        "type": "array",
        "format": "string",
        "title": "extraData",
        "additionalProperties": [],
        "description": "",
        "className": "SessionDataArchive",
        "isDb": true,
        "dbType": "json",
        "naeType": "string_array"
    },
    {
        "schema": "session-data-archive",
        "key": "id",
        "type": "integer",
        "format": "",
        "title": "",
        "additionalProperties": [],
        "description": "",
        "className": "SessionDataArchive",
        "isDb": true,
        "dbType": "int",
        "naeType": "number"
    },
    {
        "schema": "session-data-archive",
        "key": "ip",
        "type": "string",
        "format": "",
        "title": "ip",
        "additionalProperties": [],
        "description": "",
        "className": "SessionDataArchive",
        "isDb": true,
        "dbType": "varchar",
        "naeType": "string"
    },
    {
        "schema": "session-data-archive",
        "key": "isEu",
        "type": "boolean",
        "format": "",
        "title": "isEu",
        "additionalProperties": [],
        "description": "",
        "className": "SessionDataArchive",
        "isDb": true,
        "dbType": "tinyint",
        "naeType": "bool"
    },
    {
        "schema": "session-data-archive",
        "key": "origin",
        "type": "string",
        "format": "text",
        "title": "origin",
        "additionalProperties": [],
        "description": "",
        "className": "SessionDataArchive",
        "isDb": true,
        "dbType": "longtext",
        "naeType": "text"
    },
    {
        "schema": "session-data-archive",
        "key": "project",
        "type": "string",
        "format": "",
        "title": "project",
        "additionalProperties": [],
        "description": "",
        "className": "SessionDataArchive",
        "isDb": true,
        "dbType": "varchar",
        "naeType": "string"
    },
    {
        "schema": "session-data-archive",
        "key": "query",
        "type": "array",
        "format": "string",
        "title": "query",
        "additionalProperties": [],
        "description": "",
        "className": "SessionDataArchive",
        "isDb": true,
        "dbType": "json",
        "naeType": "string_array"
    },
    {
        "schema": "session-data-archive",
        "key": "referer",
        "type": "string",
        "format": "text",
        "title": "referer",
        "additionalProperties": [],
        "description": "",
        "className": "SessionDataArchive",
        "isDb": true,
        "dbType": "longtext",
        "naeType": "text"
    },
    {
        "schema": "session-data-archive",
        "key": "slug",
        "type": "string",
        "format": "",
        "title": "slug",
        "additionalProperties": [],
        "description": "",
        "className": "SessionDataArchive",
        "isDb": true,
        "dbType": "varchar",
        "naeType": "string"
    },
    {
        "schema": "session-data-archive",
        "key": "updatedAt",
        "type": "string",
        "format": "date-time",
        "title": "updatedAt",
        "additionalProperties": [],
        "description": "",
        "className": "SessionDataArchive",
        "isDb": true,
        "dbType": "datetime",
        "naeType": "string"
    },
    {
        "schema": "session-data-archive",
        "key": "useragent",
        "type": "string",
        "format": "text",
        "title": "useragent",
        "additionalProperties": [],
        "description": "",
        "className": "SessionDataArchive",
        "isDb": true,
        "dbType": "longtext",
        "naeType": "text"
    },
    {
        "schema": "session-data-archive",
        "key": "uuid",
        "type": "string",
        "format": "",
        "title": "uuid",
        "additionalProperties": [],
        "description": "",
        "className": "SessionDataArchive",
        "isDb": true,
        "dbType": "varchar",
        "naeType": "string"
    },
    {
        "schema": "session-device",
        "key": "browser",
        "type": "string",
        "format": "",
        "title": "browser",
        "additionalProperties": [],
        "description": "",
        "className": "SessionDevice",
        "isDb": true,
        "dbType": "varchar",
        "naeType": "string"
    },
    {
        "schema": "session-device",
        "key": "device",
        "type": "string",
        "format": "",
        "title": "device",
        "additionalProperties": [],
        "description": "",
        "className": "SessionDevice",
        "isDb": true,
        "dbType": "varchar",
        "naeType": "string"
    },
    {
        "schema": "session-device",
        "key": "id",
        "type": "integer",
        "format": "",
        "title": "",
        "additionalProperties": [],
        "description": "",
        "className": "SessionDevice",
        "isDb": true,
        "dbType": "int",
        "naeType": "number"
    },
    {
        "schema": "session-device",
        "key": "isDesktop",
        "type": "boolean",
        "format": "",
        "title": "isDesktop",
        "additionalProperties": [],
        "description": "",
        "className": "SessionDevice",
        "isDb": true,
        "dbType": "tinyint",
        "naeType": "bool"
    },
    {
        "schema": "session-device",
        "key": "isMobile",
        "type": "boolean",
        "format": "",
        "title": "isMobile",
        "additionalProperties": [],
        "description": "",
        "className": "SessionDevice",
        "isDb": true,
        "dbType": "tinyint",
        "naeType": "bool"
    },
    {
        "schema": "session-device",
        "key": "isTablet",
        "type": "boolean",
        "format": "",
        "title": "isTablet",
        "additionalProperties": [],
        "description": "",
        "className": "SessionDevice",
        "isDb": true,
        "dbType": "tinyint",
        "naeType": "bool"
    },
    {
        "schema": "session-device",
        "key": "os",
        "type": "string",
        "format": "",
        "title": "os",
        "additionalProperties": [],
        "description": "",
        "className": "SessionDevice",
        "isDb": true,
        "dbType": "varchar",
        "naeType": "string"
    },
    {
        "schema": "session-device",
        "key": "uuid",
        "type": "string",
        "format": "",
        "title": "uuid",
        "additionalProperties": [],
        "description": "",
        "className": "SessionDevice",
        "isDb": true,
        "dbType": "varchar",
        "naeType": "string"
    },
    {
        "schema": "session-geo",
        "key": "country",
        "type": "string",
        "format": "",
        "title": "country",
        "additionalProperties": [],
        "description": "",
        "className": "SessionGeo",
        "isDb": true,
        "dbType": "varchar",
        "naeType": "string"
    },
    {
        "schema": "session-geo",
        "key": "id",
        "type": "integer",
        "format": "",
        "title": "",
        "additionalProperties": [],
        "description": "",
        "className": "SessionGeo",
        "isDb": true,
        "dbType": "int",
        "naeType": "number"
    },
    {
        "schema": "session-geo",
        "key": "ip",
        "type": "string",
        "format": "",
        "title": "ip",
        "additionalProperties": [],
        "description": "",
        "className": "SessionGeo",
        "isDb": true,
        "dbType": "varchar",
        "naeType": "string"
    },
    {
        "schema": "session-geo",
        "key": "isEu",
        "type": "boolean",
        "format": "",
        "title": "isEu",
        "additionalProperties": [],
        "description": "",
        "className": "SessionGeo",
        "isDb": true,
        "dbType": "tinyint",
        "naeType": "bool"
    },
    {
        "schema": "session-geo",
        "key": "state",
        "type": "string",
        "format": "",
        "title": "state",
        "additionalProperties": [],
        "description": "",
        "className": "SessionGeo",
        "isDb": true,
        "dbType": "varchar",
        "naeType": "string"
    },
    {
        "schema": "session-geo",
        "key": "stateTax",
        "type": "number",
        "format": "float",
        "title": "stateTax",
        "additionalProperties": [],
        "description": "",
        "className": "SessionGeo",
        "isDb": true,
        "dbType": "double",
        "naeType": "float"
    },
    {
        "schema": "session-geo",
        "key": "uuid",
        "type": "string",
        "format": "",
        "title": "uuid",
        "additionalProperties": [],
        "description": "",
        "className": "SessionGeo",
        "isDb": true,
        "dbType": "varchar",
        "naeType": "string"
    },
    {
        "schema": "session-quiz",
        "key": "analyticsId",
        "type": "string",
        "format": "",
        "title": "analyticsId",
        "additionalProperties": [],
        "description": "",
        "className": "SessionQuiz",
        "isDb": true,
        "dbType": "varchar",
        "naeType": "string"
    },
    {
        "schema": "session-quiz",
        "key": "createdAt",
        "type": "string",
        "format": "date-time",
        "title": "createdAt",
        "additionalProperties": [],
        "description": "",
        "className": "SessionQuiz",
        "isDb": true,
        "dbType": "datetime",
        "naeType": "string"
    },
    {
        "schema": "session-quiz",
        "key": "id",
        "type": "integer",
        "format": "",
        "title": "",
        "additionalProperties": [],
        "description": "",
        "className": "SessionQuiz",
        "isDb": true,
        "dbType": "int",
        "naeType": "number"
    },
    {
        "schema": "session-quiz",
        "key": "quizData",
        "type": "array",
        "format": "string",
        "title": "quizData",
        "additionalProperties": [],
        "description": "",
        "className": "SessionQuiz",
        "isDb": true,
        "dbType": "json",
        "naeType": "string_array"
    },
    {
        "schema": "session-quiz",
        "key": "uuid",
        "type": "string",
        "format": "",
        "title": "uuid",
        "additionalProperties": [],
        "description": "",
        "className": "SessionQuiz",
        "isDb": true,
        "dbType": "varchar",
        "naeType": "string"
    },
    {
        "schema": "sf-explore-data-folder",
        "key": "sort",
        "type": "number",
        "format": "",
        "title": "Sort",
        "additionalProperties": [],
        "description": "",
        "className": "SfExploreDataFolder",
        "isDb": true,
        "dbType": "int",
        "naeType": "string"
    },
    {
        "schema": "sf-explore-data-folder",
        "key": "title",
        "type": "string",
        "format": "",
        "title": "Title",
        "additionalProperties": [],
        "description": "",
        "className": "SfExploreDataFolder",
        "isDb": true,
        "dbType": "varchar",
        "naeType": "string"
    },
    {
        "schema": "sf-explore-data-item",
        "key": "columns",
        "type": "string",
        "format": "text",
        "title": "columns",
        "additionalProperties": [],
        "description": "",
        "className": "SfExploreDataItem",
        "isDb": true,
        "dbType": "varchar",
        "naeType": "text"
    },
    {
        "schema": "sf-explore-data-item",
        "key": "exploreId",
        "type": "string",
        "format": "",
        "title": "exploreId",
        "additionalProperties": [],
        "description": "",
        "className": "SfExploreDataItem",
        "isDb": true,
        "dbType": "varchar",
        "naeType": "string"
    },
    {
        "schema": "sf-explore-data-item",
        "key": "folder",
        "type": "rel",
        "format": "sf-explore-data-folder",
        "title": "Folder",
        "additionalProperties": [],
        "description": "",
        "className": "SfExploreDataItem",
        "isDb": true,
        "dbType": "int",
        "naeType": "object"
    },
    {
        "schema": "sf-explore-data-item",
        "key": "sort",
        "type": "number",
        "format": "",
        "title": "Sort",
        "additionalProperties": [],
        "description": "",
        "className": "SfExploreDataItem",
        "isDb": true,
        "dbType": "int",
        "naeType": "string"
    },
    {
        "schema": "sf-explore-data-item",
        "key": "sqlCount",
        "type": "string",
        "format": "text",
        "title": "sqlCount",
        "additionalProperties": [],
        "description": "",
        "className": "SfExploreDataItem",
        "isDb": true,
        "dbType": "varchar",
        "naeType": "text"
    },
    {
        "schema": "sf-explore-data-item",
        "key": "sqlData",
        "type": "string",
        "format": "text",
        "title": "sqlData",
        "additionalProperties": [],
        "description": "",
        "className": "SfExploreDataItem",
        "isDb": true,
        "dbType": "varchar",
        "naeType": "text"
    },
    {
        "schema": "sf-explore-data-item",
        "key": "title",
        "type": "string",
        "format": "",
        "title": "title",
        "additionalProperties": [],
        "description": "",
        "className": "SfExploreDataItem",
        "isDb": true,
        "dbType": "varchar",
        "naeType": "string"
    },
    {
        "schema": "sf-key-value-orm",
        "key": "sfKey",
        "type": "string",
        "format": "",
        "title": "sfKey",
        "additionalProperties": [],
        "description": "",
        "className": "SfKeyValueOrm",
        "isDb": true,
        "dbType": "varchar",
        "naeType": "string"
    },
    {
        "schema": "sf-key-value-orm",
        "key": "sfKey",
        "type": "string",
        "format": "",
        "title": "sfKey",
        "additionalProperties": [],
        "description": "",
        "className": "SfKeyValueOrm",
        "isDb": true,
        "dbType": "varchar",
        "naeType": "string"
    },
    {
        "schema": "sf-key-value-orm",
        "key": "sfValue",
        "type": "string",
        "format": "",
        "title": "sfValue",
        "additionalProperties": [],
        "description": "",
        "className": "SfKeyValueOrm",
        "isDb": true,
        "dbType": "varchar",
        "naeType": "string"
    },
    {
        "schema": "sf-key-value-orm",
        "key": "sfValue",
        "type": "string",
        "format": "",
        "title": "sfValue",
        "additionalProperties": [],
        "description": "",
        "className": "SfKeyValueOrm",
        "isDb": true,
        "dbType": "varchar",
        "naeType": "string"
    },
    {
        "schema": "user",
        "key": "allowedIp",
        "type": "string",
        "format": "text",
        "title": "",
        "additionalProperties": [],
        "description": "",
        "className": "User",
        "isDb": true,
        "dbType": "longtext",
        "naeType": "text"
    },
    {
        "schema": "user",
        "key": "authSourceId",
        "type": "integer",
        "format": "",
        "title": "authSourceId",
        "additionalProperties": [],
        "description": "",
        "className": "User",
        "isDb": true,
        "dbType": "int",
        "naeType": "number"
    },
    {
        "schema": "user",
        "key": "disabled",
        "type": "boolean",
        "format": "",
        "title": "",
        "additionalProperties": [],
        "description": "",
        "className": "User",
        "isDb": true,
        "dbType": "tinyint",
        "naeType": "bool"
    },
    {
        "schema": "user",
        "key": "email",
        "type": "string",
        "format": "",
        "title": "",
        "additionalProperties": [],
        "description": "",
        "className": "User",
        "isDb": true,
        "dbType": "varchar",
        "naeType": "string"
    },
    {
        "schema": "user",
        "key": "firstName",
        "type": "string",
        "format": "",
        "title": "",
        "additionalProperties": [],
        "description": "",
        "className": "User",
        "isDb": true,
        "dbType": "varchar",
        "naeType": "string"
    },
    {
        "schema": "user",
        "key": "fullName",
        "type": "string",
        "format": "",
        "title": "",
        "additionalProperties": [],
        "description": "",
        "className": "User",
        "isDb": false,
        "dbType": "",
        "naeType": "string"
    },
    {
        "schema": "user",
        "key": "id",
        "type": "integer",
        "format": "",
        "title": "",
        "additionalProperties": [],
        "description": "",
        "className": "User",
        "isDb": true,
        "dbType": "int",
        "naeType": "number"
    },
    {
        "schema": "user",
        "key": "lastName",
        "type": "string",
        "format": "",
        "title": "",
        "additionalProperties": [],
        "description": "",
        "className": "User",
        "isDb": true,
        "dbType": "varchar",
        "naeType": "string"
    },
    {
        "schema": "user",
        "key": "login",
        "type": "string",
        "format": "",
        "title": "",
        "additionalProperties": [],
        "description": "",
        "className": "User",
        "isDb": true,
        "dbType": "varchar",
        "naeType": "string"
    },
    {
        "schema": "user",
        "key": "mailSignature",
        "type": "string",
        "format": "text",
        "title": "",
        "additionalProperties": [],
        "description": "",
        "className": "User",
        "isDb": true,
        "dbType": "longtext",
        "naeType": "text"
    },
    {
        "schema": "user",
        "key": "password",
        "type": "string",
        "format": "",
        "title": "",
        "additionalProperties": [],
        "description": "",
        "className": "User",
        "isDb": true,
        "dbType": "varchar",
        "naeType": "string"
    },
    {
        "schema": "user",
        "key": "permissionGroup",
        "type": "string",
        "format": "",
        "title": "",
        "additionalProperties": [],
        "description": "",
        "className": "User",
        "isDb": true,
        "dbType": "varchar",
        "naeType": "string"
    },
    {
        "schema": "user",
        "key": "phone",
        "type": "string",
        "format": "",
        "title": "",
        "additionalProperties": [],
        "description": "",
        "className": "User",
        "isDb": true,
        "dbType": "varchar",
        "naeType": "string"
    },
    {
        "schema": "user",
        "key": "plainPassword",
        "type": "string",
        "format": "password",
        "title": "",
        "additionalProperties": [],
        "description": "",
        "className": "User",
        "isDb": false,
        "dbType": "",
        "naeType": "string"
    },
    {
        "schema": "user",
        "key": "position",
        "type": "string",
        "format": "",
        "title": "",
        "additionalProperties": [],
        "description": "",
        "className": "User",
        "isDb": true,
        "dbType": "varchar",
        "naeType": "string"
    },
    {
        "schema": "user",
        "key": "scopes",
        "type": "array",
        "format": "string",
        "title": "",
        "additionalProperties": [],
        "description": "",
        "className": "User",
        "isDb": false,
        "dbType": "",
        "naeType": "string_array"
    },
    {
        "schema": "user",
        "key": "superUser",
        "type": "boolean",
        "format": "",
        "title": "",
        "additionalProperties": [],
        "description": "",
        "className": "User",
        "isDb": true,
        "dbType": "tinyint",
        "naeType": "bool"
    }
]