import { CustomEditComponentsMap } from "../_generated/_custom/edit/CustomEditComponentsMap";
import { CustomListComponentsMap } from "../_generated/_custom/tabs/CustomListComponentsMap";
import { CustomViewComponentsMap } from "../_generated/_custom/view/CustomViewComponentsMap";
import { Ip2locationSchema } from "./ConfigSchema/Ip2locationSchema";

export const PluginsMap: any = {
    ...CustomViewComponentsMap,
    ...CustomEditComponentsMap,
    ...CustomListComponentsMap,

    config: {
        ip2location: Ip2locationSchema,
    }
}