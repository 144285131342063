import { INaeSchema } from "@newageerp/v3.app.main-bundle"
export const NaeSSchema: INaeSchema[] = [
    {
        "className": "KeyValueData",
        "schema": "key-value-data",
        "title": "Key value data",
        "titlePlural": "Key value data",
        "required": [],
        "scopes": []
    },
    {
        "className": "KlaviyoEvent",
        "schema": "klaviyo-event",
        "title": "",
        "titlePlural": "",
        "required": [],
        "scopes": []
    },
    {
        "className": "SessionData",
        "schema": "session-data",
        "title": "Session",
        "titlePlural": "Sessions",
        "required": [],
        "scopes": []
    },
    {
        "className": "SessionDataArchive",
        "schema": "session-data-archive",
        "title": "Session Archive",
        "titlePlural": "Sessions Archive",
        "required": [],
        "scopes": []
    },
    {
        "className": "SessionDevice",
        "schema": "session-device",
        "title": "",
        "titlePlural": "",
        "required": [],
        "scopes": []
    },
    {
        "className": "SessionGeo",
        "schema": "session-geo",
        "title": "",
        "titlePlural": "",
        "required": [],
        "scopes": []
    },
    {
        "className": "SessionQuiz",
        "schema": "session-quiz",
        "title": "",
        "titlePlural": "",
        "required": [],
        "scopes": []
    },
    {
        "className": "SfExploreDataFolder",
        "schema": "sf-explore-data-folder",
        "title": "Explore data folder",
        "titlePlural": "Explore data folder",
        "required": [],
        "scopes": []
    },
    {
        "className": "SfExploreDataItem",
        "schema": "sf-explore-data-item",
        "title": "Explore data",
        "titlePlural": "Explore data",
        "required": [],
        "scopes": []
    },
    {
        "className": "SfKeyValueOrm",
        "schema": "sf-key-value-orm",
        "title": "Key-value",
        "titlePlural": "Key-value",
        "required": [],
        "scopes": []
    },
    {
        "className": "SfKeyValueOrm",
        "schema": "sf-key-value-orm",
        "title": "Key-value",
        "titlePlural": "Key-value",
        "required": [],
        "scopes": []
    },
    {
        "className": "User",
        "schema": "user",
        "title": "",
        "titlePlural": "",
        "required": [],
        "scopes": []
    }
]
        export const NaeSSchemaMap = {
    "SessionData": {
        "className": "SessionData",
        "schema": "session-data"
    },
    "SfKeyValueOrm": {
        "className": "SfKeyValueOrm",
        "schema": "sf-key-value-orm"
    },
    "KeyValueData": {
        "className": "KeyValueData",
        "schema": "key-value-data"
    },
    "User": {
        "className": "User",
        "schema": "user"
    },
    "SfExploreDataItem": {
        "className": "SfExploreDataItem",
        "schema": "sf-explore-data-item"
    },
    "SfExploreDataFolder": {
        "className": "SfExploreDataFolder",
        "schema": "sf-explore-data-folder"
    },
    "SessionDataArchive": {
        "className": "SessionDataArchive",
        "schema": "session-data-archive"
    },
    "SessionDevice": {
        "className": "SessionDevice",
        "schema": "session-device"
    },
    "KlaviyoEvent": {
        "className": "KlaviyoEvent",
        "schema": "klaviyo-event"
    },
    "SessionGeo": {
        "className": "SessionGeo",
        "schema": "session-geo"
    },
    "SessionQuiz": {
        "className": "SessionQuiz",
        "schema": "session-quiz"
    }
}