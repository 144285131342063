import { ORM } from "redux-orm";
import KeyValueDataModel from "./KeyValueDataModel"
import QueueModel from "./QueueModel"
import SessionDataModel from "./SessionDataModel"
import SessionDataArchiveModel from "./SessionDataArchiveModel"

export const orm = new ORM({
    stateSelector: (state) => {
        return state.orm; // wherever the reducer is put during createStore
    },
});
orm.register(
    KeyValueDataModel,
    QueueModel,
    SessionDataModel,
    SessionDataArchiveModel,
);

export default orm;