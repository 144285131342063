import { IKeyValueDataFieldsNae } from './useKeyValueDataHookNae';
import { ISessionDataFieldsNae } from './useSessionDataHookNae';
import { ISessionDataArchiveFieldsNae } from './useSessionDataArchiveHookNae';
import { SelectorKeyValueDataNae,SelectorSessionDataNae,SelectorSessionDataArchiveNae, } from "../models/ormSelectors"
import { NaeSSchemaMap } from '../config/NaeSSchema';
import { store } from '../models/ormstore';

export const getDataCacheForSchema = (className: string) => {
    if (className === NaeSSchemaMap.KeyValueData.className) {
            return {
                dataToCheck: SelectorKeyValueDataNae(store.getState()),
                fields: IKeyValueDataFieldsNae,    
            }
        }
    if (className === NaeSSchemaMap.SessionData.className) {
            return {
                dataToCheck: SelectorSessionDataNae(store.getState()),
                fields: ISessionDataFieldsNae,    
            }
        }
    if (className === NaeSSchemaMap.SessionDataArchive.className) {
            return {
                dataToCheck: SelectorSessionDataArchiveNae(store.getState()),
                fields: ISessionDataArchiveFieldsNae,    
            }
        }
        return undefined;
}