
import React, { Fragment } from 'react'
import { PropsId, PropsLink } from './types';
import moment from "moment";
import { SelectorKeyValueDataNae, SelectorSessionDataNae, SelectorSessionDataArchiveNae } from '../models/ormSelectors';
import { useKeyValueDataHookNae } from '../hooks/useKeyValueDataHookNae';
import { useSessionDataHookNae } from '../hooks/useSessionDataHookNae';
import { useSessionDataArchiveHookNae } from '../hooks/useSessionDataArchiveHookNae';


export const useEmptyHook = (id: number) => {
            return undefined;
        }

export const getHookForSchema = (schema: string) => {
  let selector : any = useEmptyHook;
  if (schema === 'key-value-data') {
    return useKeyValueDataHookNae;
  }
  if (schema === 'session-data') {
    return useSessionDataHookNae;
  }
  if (schema === 'session-data-archive') {
    return useSessionDataArchiveHookNae;
  }
  return selector;
}

export function KeyValueDataEmptyFieldNae(props: PropsLink) {
  const element = useKeyValueDataHookNae(props.id ? props.id : -1);
  if (!element) {
      return <Fragment />
  }

  return <Fragment/>
}

export function SessionDataEmptyFieldNae(props: PropsLink) {
  const element = useSessionDataHookNae(props.id ? props.id : -1);
  if (!element) {
      return <Fragment />
  }

  return <Fragment/>
}

export function SessionDataArchiveEmptyFieldNae(props: PropsLink) {
  const element = useSessionDataArchiveHookNae(props.id ? props.id : -1);
  if (!element) {
      return <Fragment />
  }

  return <Fragment/>
}

