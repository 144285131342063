import { createSelector } from "redux-orm";
import orm from "./orm";

export const SelectorKeyValueDataNae = createSelector(orm.KeyValueDataModel);
export const SelectorQueueNae = createSelector(orm.QueueModel);
export const SelectorSessionDataNae = createSelector(orm.SessionDataModel);
export const SelectorSessionDataArchiveNae = createSelector(orm.SessionDataArchiveModel);

export const selectorBySchemaClassName = (schema) => {
        if (schema === 'KeyValueData') {
        return SelectorKeyValueDataNae;
    }
        if (schema === 'Queue') {
        return SelectorQueueNae;
    }
        if (schema === 'SessionData') {
        return SelectorSessionDataNae;
    }
        if (schema === 'SessionDataArchive') {
        return SelectorSessionDataArchiveNae;
    }
    }

export const selectorBySchemaSlug = (schema) => {
        if (schema === 'key-value-data') {
        return SelectorKeyValueDataNae;
    }
        if (schema === '') {
        return SelectorQueueNae;
    }
        if (schema === 'session-data') {
        return SelectorSessionDataNae;
    }
        if (schema === 'session-data-archive') {
        return SelectorSessionDataArchiveNae;
    }
    }